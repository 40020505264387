import { Col, message, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllExams } from "../../../apicalls/exams";
import { HideLoading, ShowLoading } from "../../../redux/loaderSlice";
import PageTitle from "../../../components/PageTitle";
import { useNavigate } from "react-router-dom";
function Home() {
  const [exams, setExams] = React.useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const getExams = async () => {
    try {
      dispatch(ShowLoading());
      const response = await getAllExams();
      if (response.success) {
        setExams(response.data);
      } else {
        message.error(response.message);
      }
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  useEffect(() => {
    getExams();
  }, []);

  return (
    user && (
      <div>
        <div style={{ textAlign: 'right' }}>
          <PageTitle title={`Welcome ${user.name}!`} />
        </div>
        <div className="divider"></div>
        <div
          className="background-image-container"
          style={{
            position: "relative",
          }}
        >
          <div
            className="background-image"
            style={{
              backgroundImage: "url('/books-still-life-cartoon-style3.jpg')",
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: -1,
            }}
          ></div>
          <div className="exam-cards-container">
            <Row gutter={[16, 16]}>
              {exams.map((exam) => (
                <Col xs={24} sm={12} md={8} lg={6} xl={6} key={exam._id}>
                  <div className="card-lg flex flex-col gap-1 p-2">
                    <h1 className="text-2xl">{exam?.name}</h1>
                    <h1 className="text-md">Tingkat : {exam.category}</h1>
                    <span className="text-xs">Jumlah Soal : {exam.totalMarks}</span>
                    <span className="text-xs">Passing grade : {exam.passingMarks}</span>
                    <span className="text-xs">Durasi : {exam.duration}</span>
                    <button
                      className="primary-outlined-btn"
                      onClick={() => navigate(`/user/write-exam/${exam._id}`)}
                    >
                      Mulai Ujian
                    </button>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    )
  );  
}

export default Home;
