import { Form, message } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { loginUser } from "../../../apicalls/users";
import { HideLoading, ShowLoading } from "../../../redux/loaderSlice";

function Login() {
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    try {
      dispatch(ShowLoading());
      const response = await loginUser(values);
      dispatch(HideLoading());
      if (response.success) {
        message.success(response.message);
        localStorage.setItem("token", response.data);
        window.location.href = "/";
      } else {
        message.error(response.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  return (
      <div
        className="flex justify-center items-center h-screen w-screen"
        style={{
          position: "relative",
          backgroundImage: "url('/books-still-life-cartoon-style.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 100%)",
          }}
        />

      <div className="card w-400 p-3 bg-white">
        <div className="flex flex-col">
          <div className="flex">
            <h1 className="text-2xl logo">
              {" "}
              <img src="/Logo.png" /> Diagnostic Science Literacy Test - Masuk{" "}
              <i class="ri-login-circle-line"></i>
            </h1>
          </div>
          <div className="divider"></div>
          <Form layout="vertical" className="mt-2" onFinish={onFinish}>
            <Form.Item name="email" label="User">
              <input type="text" />
            </Form.Item>
            <Form.Item name="password" label="Code">
              <input type="password" />
            </Form.Item>

            <div className="flex flex-col gap-2">
              <button type="submit" className="primary-contained-btn mt-2 w-100">
                Masuk
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
