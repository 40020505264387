import React from "react";
import { useNavigate } from "react-router-dom";

function Instructions({ examData, setView, startTimer }) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center gap-5">
      <ul className="flex flex-col gap-1">
        <h1 className="text-2xl underline">Instructions</h1>
        <li>Waktu ujian adalah {examData.duration} detik.</li>
        <li>
          Jawaban akan masuk dan ujian selesai setelah {examData.duration}{" "}
          detik.
        </li>
        <li>Tuliskan Nama sebelum mengerjakan ujian di atas Instruksi ini.</li>
        <li>Baca terlebih dahulu stimulus sebelum mengerjakan soal.</li>
        <li>Pilih satu jawaban benar pada setiap soal.</li>
        <li>Setelah mengumpulkan ujian, kamu tidak dapat mengubah jawaban.</li>
        <li>Jangan refresh browser</li>
        <li>
          Jumlah soal adalah {" "}
          <span className="font-bold">{examData.totalMarks}</span>.
        </li>
        <li>
          Jawab soal di atas Passing Grade{" "}
          <span className="font-bold">{examData.passingMarks}</span>.
        </li>
      </ul>

      <div className="flex gap-2">
        <button className="primary-outlined-btn"
         onClick={()=>navigate('/')}
        >
              Tutup
        </button>
        <button
          className="primary-contained-btn"
          onClick={() => {
            startTimer();
            setView("questions");
          }}
        >
          Mulai Ujian
        </button>
      </div>
    </div>
  );
}

export default Instructions;
