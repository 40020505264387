import { Form, message, Modal, Upload } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addQuestionToExam, editQuestionById } from "../../../apicalls/exams";
import { HideLoading, ShowLoading } from "../../../redux/loaderSlice";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { app } from "../../../firebase";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddEditQuestion({
  showAddEditQuestionModal,
  setShowAddEditQuestionModal,
  refreshData,
  examId,
  selectedQuestion,
  setSelectedQuestion,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['clean']
    ],
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent'
  ];

  const onFinish = async (values) => {
    try {
      dispatch(ShowLoading());
      const requiredPayload = {
        image: values.image,
        questionNumber: values.questionNumber,
        name: values.name,
        image2: values.image2,
        summary: values.summary,
        correctOption: values.correctOption,
        options: {
          A: values.A,
          B: values.B,
          C: values.C,
          D: values.D,
        },
        exam: examId,
      };

      // Save user-selected option to userSelectedOption
      requiredPayload.userSelectedOption = values.userSelectedOption;

      let response;
      if (selectedQuestion) {
        response = await editQuestionById({
          ...requiredPayload,
          questionId: selectedQuestion._id,
        });
      } else {
        response = await addQuestionToExam(requiredPayload);
      }
      if (response.success) {
        message.success(response.message);
        refreshData();
        setShowAddEditQuestionModal(false);
      } else {
        message.error(response.message);
      }
      setSelectedQuestion(null);
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const handleUpload = async (file, fieldName) => {
    try {
      const storage = getStorage(app);
      const fileName = new Date().getTime() + "-" + file.name;
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          message.error("Failed to upload image");
          console.error(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            form.setFieldsValue({ [fieldName]: downloadURL });
            message.success("Image uploaded successfully");
          });
        }
      );
    } catch (error) {
      message.error("Failed to upload image");
      console.error(error);
    }
  };  

  return (
    <Modal
      title={selectedQuestion ? "Edit Question" : "Add Question"}
      visible={showAddEditQuestionModal}
      footer={null}
      onCancel={() => {
        setShowAddEditQuestionModal(false);
        setSelectedQuestion(null);
      }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          questionNumber: selectedQuestion?.questionNumber,
          image: selectedQuestion?.image,
          name: selectedQuestion?.name,
          image: selectedQuestion?.image2,
          summary: selectedQuestion?.summary,
          A: selectedQuestion?.options?.A,
          B: selectedQuestion?.options?.B,
          C: selectedQuestion?.options?.C,
          D: selectedQuestion?.options?.D,
          correctOption: selectedQuestion?.correctOption,
        }}
      >
        <Form.Item name="questionNumber" label="Nomor">
          <input type="text" />
        </Form.Item>
        <Form.Item name="image" label="Ilustrasi">
          <Upload
            accept="image/*"
            beforeUpload={(file) => {
              setFile(file);
              handleUpload(file, 'image');
              return false;
            }}
            showUploadList={false}
          >
            <button type="button">Upload Gambar</button>
          </Upload>
          {uploadProgress > 0 && <progress value={uploadProgress} max="100" />}
        </Form.Item>
        <Form.Item name="name" label="Pertanyaan">
          <ReactQuill
            theme="snow"
            value={form.getFieldValue('summary')}
            onChange={(value) => form.setFieldsValue({ name: value })}
            modules={modules}
            formats={formats}
          />
        </Form.Item>
        <Form.Item name="image2" label="Gambar Pertanyaan">
          <Upload
            accept="image2/*"
            beforeUpload={(file) => {
              setFile(file);
              handleUpload(file, 'image2');
              return false;
            }}
            showUploadList={false}
          >
            <button type="button">Upload Gambar</button>
          </Upload>
          {uploadProgress > 0 && <progress value={uploadProgress} max="100" />}
        </Form.Item>
        <Form.Item name="summary" label="Paragraf">
          <ReactQuill
            theme="snow"
            value={form.getFieldValue('summary')}
            onChange={(value) => form.setFieldsValue({ summary: value })}
            modules={modules}
            formats={formats}
          />
        </Form.Item>

        <Form.Item name="correctOption" label="Jawaban yang Benar">
          <input type="text" />
        </Form.Item>

        <div className="flex gap-3">
          <Form.Item name="A" label="Pilihan A">
            <input type="text" />
          </Form.Item>
          <Form.Item name="B" label="Pilihan B">
            <input type="text" />
          </Form.Item>
        </div>
        <div className="flex gap-3">
          <Form.Item name="C" label="Pilihan C">
            <input type="text" />
          </Form.Item>
          <Form.Item name="D" label="Pilihan D">
            <input type="text" />
          </Form.Item>
        </div>

        <div className="flex justify-end mt-2 gap-3">
          <button
            className="primary-outlined-btn"
            type="button"
            onClick={() => {
              setShowAddEditQuestionModal(false);
              setSelectedQuestion(null);
            }}
          >
            Batal
          </button>
          <button className="primary-contained-btn" type="submit">
            Simpan
          </button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddEditQuestion;
