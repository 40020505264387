import React, { useEffect, useState } from "react";
import { message, Table } from "antd";
import { useDispatch } from "react-redux";
import { HideLoading, ShowLoading } from "../../../redux/loaderSlice";
import { getAllReports } from "../../../apicalls/reports";
import moment from "moment";
import { saveAs } from "file-saver";
import PageTitle from "../../../components/PageTitle";

function AdminReports() {
  const [reportsData, setReportsData] = useState([]);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    examName: "",
    userName: "",
  });

  const columns = [
    {
      title: "Ujian",
      dataIndex: "examName",
      render: (text, record) => <>{record.exam.name}</>,
    },
    {
      title: "Kelas",
      dataIndex: "userName",
      render: (text, record) => <>{record.user.name}</>,
    },
    {
      title: "Nama",
      dataIndex: "userName",
      render: (text, record) => <>{record.result.userName}</>,
    },
    {
      title: "Tanggal",
      dataIndex: "date",
      render: (text, record) => (
        <>{moment(record.createdAt).format("DD-MM-YYYY hh:mm:ss")}</>
      ),
    },
    {
      title: "Total Soal",
      dataIndex: "totalMarks",
      render: (text, record) => <>{record.exam.totalMarks}</>,
    },
    {
      title: "Passing Grade",
      dataIndex: "passingMarks",
      render: (text, record) => <>{record.exam.passingMarks}</>,
    },
    {
      title: "Hasil",
      dataIndex: "verdict",
      render: (text, record) => <>{record.result.verdict}</>,
    },
    {
      title: "Jawaban Benar",
      dataIndex: "correctAnswers",
      render: (text, record) => (
        <>
          {record.result.correctAnswers.map((answer) => (
            <div key={answer.questionIndex}>
              <div>No: {answer.questionIndex}</div>
              <div>{answer.selectedOption}</div>
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Jawaban Salah",
      dataIndex: "wrongAnswers",
      render: (text, record) => (
        <>
          {record.result.wrongAnswers.map((answer) => (
            <div key={answer.questionIndex}>
              <div>No:{answer.questionIndex}</div>
              <div>{answer.selectedOption}</div>
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Semua Jawaban",
      dataIndex: "allAnswers",
      render: (text, record) => (
        <div>
          {record.result.allAnswers.map((answer, index) => (
            <div key={index}>
              <div>{answer.questionIndex}. </div>
              <div>{answer.selectedOption}</div>
            </div>
          ))}
        </div>
      ),
    },
  ];

  const getData = async (tempFilters) => {
    try {
      dispatch(ShowLoading());
      const response = await getAllReports(tempFilters);
      if (response.success) {
        setReportsData(response.data);
      } else {
        message.error(response.message);
      }
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const downloadCSV = () => {
    const csvData = reportsData.map((report) => ({
      "Ujian": report.exam.name,
      "Kelas": report.user.name,
      "Nama": report.result.userName,
      Date: moment(report.createdAt).format("DD-MM-YYYY hh:mm:ss"),
      "Total Soal": report.exam.totalMarks,
      "Passing Grade": report.exam.passingMarks,
      Verdict: report.result.verdict,
      "Jawaban benar": report.result.correctAnswers
        .map(
          (answer) =>
            `Question Index: ${answer.questionIndex}, Selected Answer: ${answer.selectedOption}`
        )
        .join(";"),
      "Jawaban salah": report.result.wrongAnswers
        .map(
          (answer) =>
            `Question Index: ${answer.questionIndex}, Selected Answer: ${answer.selectedOption}`
        )
        .join(";"),
      "Semua Jawaban": report.result.allAnswers
      .map((answer) => `${answer.questionIndex}, ${answer.selectedOption}`)
      .join(";"),
          
    }));

    const csvColumns = Object.keys(csvData[0]).join(",");
    const csvRows = csvData.map((data) => Object.values(data).join(","));
    const csv = `${csvColumns}\n${csvRows.join("\n")}`;

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "exam_reports.csv");
  };

  useEffect(() => {
    getData(filters);
  }, []);

  return (
    <div>
      <PageTitle title="Reports" />
      <div className="divider"></div>
      <div className="flex gap-2">
        <input
          type="text"
          placeholder="Exam"
          value={filters.examName}
          onChange={(e) => setFilters({ ...filters, examName: e.target.value })}
        />
        <input
          type="text"
          placeholder="User"
          value={filters.userName}
          onChange={(e) => setFilters({ ...filters, userName: e.target.value })}
        />
        <button
          className="primary-outlined-btn"
          onClick={() => {
            setFilters({
              examName: "",
              userName: "",
            });
            getData({
              examName: "",
              userName: "",
            });
          }}
        >
          Clear
        </button>
        <button className="primary-contained-btn" onClick={() => getData(filters)}>
          Cari
        </button>
        <button className="primary-contained-btn" onClick={() => downloadCSV()}>
          Download CSV
        </button>
      </div>
      <Table columns={columns} dataSource={reportsData} className="mt-2" />
    </div>
  );
}

export default AdminReports;
