// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAIWreE9FZkUifY3UYaTzd720hmSF18YXY",
  authDomain: "diagnostic-literacy.firebaseapp.com",
  projectId: "diagnostic-literacy",
  storageBucket: "diagnostic-literacy.appspot.com",
  messagingSenderId: "623571345580",
  appId: "1:623571345580:web:eed47948b4769938f97f09"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export { app };